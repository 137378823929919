/* SubmissionsPage.module.css */
.pageContainer {
  background-color: #f5f5f5; /* Light grey background, similar to Google Analytics */
  height: 100%; /* Full viewport height */
  margin: 0px;
  padding: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.headerTitle {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.subHeader {
  font-size: 16px;
  color: #666;
}

/* Additional styles for table, buttons, etc., can be added here */
