.navbar {
  background-color: #FFFFFF;
  color: #000000;
}

.publicButton {
  text-transform: none;
  border-radius: 4px;
  padding: 6px 16px;
  border: 1px solid;
  margin-right: 16px;
  height: 36px;
}

.signUpButton {
  text-transform: none;
  border-radius: 4px;
  padding: 6px 16px;
  background-color: #4285F4;
  color: #FFFFFF;
  height: 36px;
  box-shadow: none;
}

.signUpLink {
  text-decoration: none;
}

/* Back arrow button */
.backButton {
  color: #4285F4;
  padding: 0; /* Remove padding to make it smaller */
  margin-right: 16px;
}

/* Logo styling */
.logo {
  width: 60px;
  height: 60px;
  margin-right: 8px; /* Add some spacing between the logo and text */
}

/* Breadcrumbs styling */
.breadcrumbs {
  margin-left: auto;
  display: flex;
  align-items: center;
}

/* Breadcrumb link styling */
.breadcrumbLink {
  text-decoration: none;
  color: #000000;
  margin-right: 4px; /* Add spacing between breadcrumb links */
}

/* Breadcrumb separator styling */
.breadcrumbSeparator {
  color: #000000;
  margin: 0 4px; /* Add spacing around the separator */
}
